import { LoginConfiguration } from '@nodes-tech/nodesmarket-models';
import { PopulateFromEmbeddingsMap } from '@newApi/models/api/populate-from-embeddings-map';
import { EmbeddingsMap } from '@common/utilities/nodes-api/types';
import { PureDto } from '@common/types/pure-dto';

export class LoginConfigurationModel extends LoginConfiguration implements PopulateFromEmbeddingsMap {
  constructor(source?: PureDto<LoginConfiguration>) {
    super();
    super.init(source);
  }

  populateFromEmbeddingsMap(embeddings: EmbeddingsMap): void {
  }
}
